import { useEffect, useState, forwardRef, useMemo } from 'react'
import {
	apiReceptionItemSelectByPatientInfoV2,
	clientListBySystemUserInfo,
	testItemList,
} from 'api/api'
import {
	Grid,
	Box,
	Button,
	TextField,
	Typography,
	Dialog,
	DialogContent,
	useMediaQuery,
	useTheme,
	styled,
	LinearProgress,
	Slider,
	Checkbox,
} from '@mui/material'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {
	DataGridPremium,
	koKR,
	GridColDef,
	GridSelectionModel,
	GridRowModel,
	GridApi,
	useGridApiRef,
	GridDensity,
	GridValidRowModel,
	GridRowId,
	GridRowParams,
} from '@mui/x-data-grid-premium'
import { valuesInIterator } from 'util/objectUtil'
import { getSettings } from 'helpers/storage'
import { InfoClientSearchForInternal } from 'constants/types'
import ContentPasteSearchTwoToneIcon from '@mui/icons-material/ContentPasteSearchTwoTone'
import BookmarkAddedTwoToneIcon from '@mui/icons-material/BookmarkAddedTwoTone'
import { selectuserInfo } from 'hooks/userInfo'
import { useSelector } from 'react-redux'

const Transition = forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement
	},
	ref: React.Ref<unknown>
) {
	return (
		<Slide
			direction="up"
			ref={ref}
			{...props}
		/>
	)
})

const marks = [
	{
		value: 0,
		label: '50%',
	},
	{
		value: 50,
		label: '75%',
	},
	{
		value: 100,
		label: '100%',
	},
]

function valuetext(value: number) {
	return `${value}%`
}
const ResultV2Popup = ({
	dialogOpen,
	dialogClose,
	popupDatas,
	selectedRItemID,
	onPopupMstClick,
	slvRows,
	slvColumns,
	selectedReceptionRow,
	grid1ReceptionID,
	accuracyOnChange,
}: any) => {
	const env = getSettings()
	const theme = useTheme()
	const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

	const percentWidth = (sum: number) => {
		if (sum === 2) {
			return 75
		} else if (sum === 3) {
			return 100
		} else {
			return 50
		}
	}

	const mstColumns: GridColDef[] = [
		{
			field: 'rowNum',
			headerName: 'No',
			width: 100,
			type: 'number',
		},
		{
			field: 'ReceptionID',
			headerName: '접수번호',
			width: 100,
			type: 'number',
		},
		{
			field: 'Percent',
			headerName: '수진자일치율',
			width: 100,
			align: 'center',
			renderCell: (params) => {
				const { ChartNoMatch, PatientNameMatch, SocialNumberMatch } = params.row
				const sum = ChartNoMatch + PatientNameMatch + SocialNumberMatch
				return (
					<Box
						sx={{
							color: '#fff',
							display: 'block',
							width: '100%',
							height: '18px',
							textAlign: 'center',
							position: 'relative',
							'&:after': {
								content: '""',
								display: 'block',
								position: 'absolute',
								zIndex: -1,
								top: 0,
								left: 0,
								background: '#3C4F8F',
								width: `${percentWidth(sum)}%`,
								height: '100%',
							},
						}}
					>
						{percentWidth(sum) === 50 ? (
							<>
								<span style={{ color: '#fff' }}>{percentWidth(sum)}</span>
								<span style={{ color: '#000' }}>%</span>
							</>
						) : percentWidth(sum) === 75 ? (
							<span style={{ color: '#fff' }}>{percentWidth(sum)}%</span>
						) : (
							<span>{percentWidth(sum)}%</span>
						)}
					</Box>
				)
			},
		},
		{
			field: 'ReceptionDate',
			headerName: '접수일자',
			width: 100,
		},
		{
			field: 'PatientName',
			headerName: '수진자',
			width: 100,
		},
		{
			field: 'ChartNo',
			headerName: '차트번호',
			type: 'number',
			width: 100,
		},
		{
			field: 'SocialNumber',
			headerName: '주민번호',
			width: 100,
		},
	]

	// grid
	const mstRef = useGridApiRef()
	const slvRef = useGridApiRef()
	const [mstSize, setMstSize] = useState(100)
	const [slvSize, setSlvSize] = useState(100)
	const [mstLoading, setMstLoading] = useState(false)
	const [slvLoading, setSlvLoading] = useState(false)
	const [mstPage, setMstPage] = useState(1)
	const [slvPage, setSlvPage] = useState(1)
	const [mstSelectionModel, setMstSelectionModel] = useState<GridSelectionModel>(
		[]
	)
	const [slvSelectionModel, setSlvSelectionModel] = useState<GridSelectionModel>(
		[]
	)

	const onMstRowClick = (params: any) => {
		onPopupMstClick(params)
	}

	const bottomAccuracyOnChange = (event: any) => {
		const selectedRow = mstRef.current?.getSelectedRows()
		const iterArray = valuesInIterator(selectedRow)
		console.log(iterArray)
		accuracyOnChange(event, iterArray)
	}

	return (
		<Dialog
			// fullScreen={isMdUp ? false : true}
			fullScreen={true}
			open={dialogOpen}
			onClose={dialogClose}
			TransitionComponent={Transition}
			maxWidth="lg"
		>
			<AppBar sx={{ position: 'relative' }}>
				<Toolbar variant="dense">
					<Typography
						sx={{ flex: 1 }}
						variant="h6"
						component="div"
					>
						최근 2년 검사결과
					</Typography>
					<IconButton
						edge="end"
						color="inherit"
						onClick={dialogClose}
						aria-label="close"
					>
						<CloseIcon />
					</IconButton>
				</Toolbar>
			</AppBar>
			<DialogContent>
				<Grid
					container
					spacing={2}
				>
					<Grid
						item
						sm={4}
						sx={{
							width: isMdUp ? 'auto' : '100%',
						}}
					>
						<div
							style={{
								height: isMdUp ? 'calc(100vh - 165px)' : 'auto',
							}}
						>
							<DataGridPremium
								apiRef={mstRef}
								page={mstPage}
								rows={popupDatas}
								getRowId={(row) => row.rowNum}
								columns={mstColumns}
								pageSize={mstSize}
								onPageSizeChange={(size) => setMstSize(size)}
								rowsPerPageOptions={[5, 10, 20, 50, 100, 500, 1000]}
								autoHeight={isMdUp ? false : true}
								localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
								loading={mstLoading}
								selectionModel={mstSelectionModel}
								onSelectionModelChange={(newSelection) => {
									onMstRowClick(newSelection)
									setMstSelectionModel([selectedReceptionRow.rowNum, ...newSelection])
								}}
								components={{
									LoadingOverlay: LinearProgress,
								}}
								isRowSelectable={(params: GridRowParams) =>
									params.row.ReceptionID === grid1ReceptionID ? false : true
								}
								density={(env.desnse as GridDensity) || 'compact'}
								checkboxSelection
								// disableSelectionOnClick
								rowHeight={40}
								initialState={{
									columns: {
										columnVisibilityModel: { ReceptionID: false, rowNum: false },
									},
								}}
								sx={{
									overflowX: 'scroll',
									'& .MuiDataGrid-columnHeaderTitleContainer': {
										fontSize: '13px',
									},
									'& .MuiDataGrid-cell': {
										fontSize: '13px !important',
										fontWeight: '500',
										borderRight:
											theme.palette.mode === 'dark'
												? '1px solid rgba(81, 81, 81, 1)'
												: '1px solid rgba(224, 224, 224, 1)',
									},
									'& .MuiDataGrid-cell:focus': {
										outline: 'none',
									},
									'& .MuiDataGrid-cell:focus-within': {
										outline: 'none',
									},
									'& .MuiDataGrid-row:hover, & .MuiDataGrid-row.Mui-hovered': {
										backgroundColor: 'rgba(75, 123, 236, 0.15)',
									},
									'& .MuiDataGrid-row.Mui-selected, & .MuiDataGrid-row.Mui-selected:hover, & .MuiDataGrid-row.Mui-selected.Mui-hovered':
										{
											backgroundColor: 'rgba(75, 123, 236, 0.3)',
										},
									'& .MuiDataGrid-columnHeader': {
										backgroundColor: '#3C4F8F',
										color: '#fff',
									},
									'& .MuiIconButton-root': {
										color: '#fff',
									},
									'& .MuiDataGrid-columnHeaders': { backgroundColor: '#3C4F8F' },
								}}
							/>
							<Box sx={{ width: '100%', padding: '0 20px', mt: 1 }}>
								<Typography
									variant="body1"
									component="p"
								>
									수진자 일치비율
								</Typography>
								<Slider
									aria-label="수진자일치율"
									defaultValue={50}
									getAriaValueText={valuetext}
									step={null}
									valueLabelDisplay="off"
									marks={marks}
									onChange={bottomAccuracyOnChange}
								/>
							</Box>
						</div>
					</Grid>
					<Grid
						item
						sm={8}
						sx={{
							width: isMdUp ? 'auto' : '100%',
						}}
					>
						<Box
							sx={{
								height: isMdUp ? 'calc(100vh - 90px)' : 'auto',
								width: '100%',
								'& .super-app-theme--cell': {
									backgroundColor: 'rgba(224, 183, 60, 0.55)',
									color: '#1a3e72',
									fontWeight: '600',
								},
								'& .super-app.negative': {
									backgroundColor: 'rgba(157, 255, 118, 0.49)',
									color: '#1a3e72',
									fontWeight: '600',
								},
								'& .super-app.positive': {
									backgroundColor: '#d47483',
									color: '#1a3e72',
									fontWeight: '600',
								},
							}}
						>
							<DataGridPremium
								apiRef={slvRef}
								page={slvPage}
								rows={slvRows}
								getRowId={(row) => row.rowNum}
								columns={slvColumns}
								pageSize={slvSize}
								onPageSizeChange={(size) => setSlvSize(size)}
								rowsPerPageOptions={[5, 10, 20, 50, 100, 500, 1000]}
								// pagination
								// autoHeight={true}
								autoHeight={isMdUp ? false : true}
								localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
								selectionModel={slvSelectionModel}
								onSelectionModelChange={(newSelection) =>
									setSlvSelectionModel(newSelection)
								}
								components={{
									LoadingOverlay: LinearProgress,
								}}
								loading={slvLoading}
								density={(env.desnse as GridDensity) || 'compact'}
								rowHeight={40}
								initialState={{
									columns: {
										columnVisibilityModel: {
											ReceptionID: false,
											rowNum: false,
											RItemID: false,
											TestCode: false,
										},
									},
								}}
								// getRowClassName={(params) => {
								// 	const { RItemID } = params.row
								// 	return selectedRItemID.includes(RItemID) ? 'highlight' : ''
								// }}
								sx={{
									overflowX: 'scroll',
									'& .highlight': {
										backgroundColor: 'rgba(75, 123, 236, 0.3)',
									},
									'& .MuiDataGrid-columnHeaderTitleContainer': {
										fontSize: '13px',
									},
									'& .MuiDataGrid-cell': {
										fontSize: '13px !important',
										fontWeight: '500',
										borderRight:
											theme.palette.mode === 'dark'
												? '1px solid rgba(81, 81, 81, 1)'
												: '1px solid rgba(224, 224, 224, 1)',
									},
									'& .MuiDataGrid-cell:focus': {
										outline: 'none',
									},
									'& .MuiDataGrid-cell:focus-within': {
										outline: 'none',
									},
									'& .MuiDataGrid-row:hover, & .MuiDataGrid-row.Mui-hovered': {
										backgroundColor: 'rgba(75, 123, 236, 0.15)',
									},
									'& .MuiDataGrid-row.Mui-selected, & .MuiDataGrid-row.Mui-selected:hover, & .MuiDataGrid-row.Mui-selected.Mui-hovered':
										{
											backgroundColor: 'rgba(75, 123, 236, 0.3)',
										},
									'& .MuiDataGrid-columnHeader': {
										backgroundColor: '#3C4F8F',
										color: '#fff',
									},
									'& .MuiIconButton-root': {
										color: '#fff',
									},
									'& .MuiDataGrid-columnHeaders': { backgroundColor: '#3C4F8F' },
								}}
							/>
						</Box>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	)
}

export default ResultV2Popup
